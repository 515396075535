import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello, world React</h1>
        <p>My test react app </p>
      </header>
    </div>
  );
}

export default App;
